import React from 'react'
import { Link } from 'gatsby'
import {
    navLinkItem,
    navLinks,
    navLinkText,
    navLinkTextActive
} from './menu.module.css'

export default function Menu({menuLinks}) {
    return(
        <div>
            <ul className={navLinks}>
                {menuLinks.map(link => (
                    <li
                        className={navLinkItem}
                        key={link.name}
                    >
                        <Link className={navLinkText} activeClassName={navLinkTextActive} to={link.link}>
                            {link.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}