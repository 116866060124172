import * as React from 'react'
import Header from './header'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import  {container} from './layout.module.css'

const Layout = ({ pageTitle, children }) => (
    <StaticQuery
      query={graphql`
        query SiteMapQuery {
          site {
            siteMetadata {
              title
                menuLinks {
                  name
                  link
                }
            }
          }
        }
      `}
      render={data => (
        <React.Fragment>
          <Helmet>
            <title>Ballweg US: {pageTitle}</title>
            <meta name="description" content="Ballweg family project portfolio" />
            <meta name="keywords" content="Ballweg" />
          </Helmet>
          <div className={container}>
            <Header menuLinks={data.site.siteMetadata.menuLinks} />
            <main>
              {children}
            </main>
          </div>
        </React.Fragment>
      )}
    />
  )
    
    export default Layout