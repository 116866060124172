import React from 'react'
import Menu from './menu'


const Header = ({menuLinks}) => {
    return(
        <div>
            <p>This goes above the menu</p>
            <Menu menuLinks={menuLinks}></Menu>
        </div>
    )
}

export default Header